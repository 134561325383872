import React, {  lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import BookingPage from "./pages/BookingPage";
import { AppointmentPage } from "./pages/case-manger/appointment-list";
import { ProfileDetailCaseManagerFeedback } from "./pages/case-manger/profile-manager";
import {
  RequestsPageStepFive,
  RequestsPageStepFour,
  RequestsPageStepSix,
  RequestsPageStepThree,
  RequestsPageStepTwo,
} from "./pages/case-manger/request-service";

import {  useSelector } from "react-redux";
// import { getServiceAction } from "./features/auth/authSlice";
import ScrollToTop from "./Hooks/ScrollToTop";
import PreAuthForm from "./pages/case-manger/PreAuth/PreAuthForm";
import "antd/dist/antd.less";
// import { authStep1 } from "./features/auth/authService";

// -------------------------------Lazy cload components ---------
import FamilyMember from "./pages/family-member";
import FamilyProfileDetail, {
  FamilyInsuranceDetail,
} from "./pages/family-member-detail";
import NotificationPage from "./pages/notification";
import OrderPage from "./pages/order";
import ProfileDetail, { InsuranceDetail, Setting } from "./pages/ProfileDetail";
import { ServiceDetail } from "./pages/ServicePage";

import Wallet from "./pages/wallet";
import YourAddress from "./pages/your-address";
import LoginCaseManager from "./pages/case-manger/login";
import { useLoadScript } from "@react-google-maps/api";

import AboutUs2 from "./pages/about-us";
import AboutUs from "./pages/AboutUs";
import JobApplication from "./pages/JobApplication";

import PatientList from "./pages/patient-flow/patientList";
import LandingPage from "./pages/landing";
import AthenaComponent from "./components/UI/patients/medicalRecords/vitals/athena";
import ChartComponent from "./pages/case-manger/charts/charts";
import { DoctorReferralPage, DoctorReferralPageOutgoing } from "./pages/case-manger/DoctorFlow/doctor_referral_outgoing";
import DoctorsAppointmentsList from "./pages/case-manger/DoctorFlow/doctor_appointments";
import { DoctorReferralPageIncoming } from "./pages/case-manger/DoctorFlow/doctor_referral_incoming";
import SurveyForm from "./components/UI/survey/SurveyForm";
import { Survey } from "./components/UI/survey/Survey";
import { SurveySubmissions } from "./components/UI/survey/SurveySubmissions";
import ConferenceForm from "./components/UI/conference/conferencepage";
import ReleaseNotes from "./pages/release-note/release-note";
import RatingForm from "./components/UI/rating/ratinguser";
import OutgoingSurveyForm from "./components/UI/survey/OutgoingSurveyForm";
import DemoScreenPage from "./components/DemoScreenPage";

const HomePage = lazy(() => import("./pages/home"));

const PatientAppointmentList = React.lazy(() =>
  import("./pages/patient-flow/patientList").then((module) => ({
    default: module.PatientAppointmentList,
  }))
);

const AddPatientInsurance = React.lazy(() =>
  import("./pages/patient-flow/patientList").then((module) => ({
    default: module.AddPatientInsurance,
  }))
);

const AddPatientAddress = React.lazy(() =>
  import("./pages/patient-flow/patientList").then((module) => ({
    default: module.AddPatientAddress,
  }))
);

const PaymentSuccess = React.lazy(() => import("./pages/payment-success"));

const DataPolicy = React.lazy(() =>
  import("./pages/links").then((module) => ({
    default: module.DataPolicy,
  }))
);

const FaqScreen = React.lazy(() =>
  import("./pages/links").then((module) => ({
    default: module.FaqScreen,
  }))
);

const PrivacyPolicy = React.lazy(() =>
  import("./pages/links").then((module) => ({
    default: module.PrivacyPolicy,
  }))
);
const TermsAndCondition = React.lazy(() =>
  import("./pages/links").then((module) => ({
    default: module.TermsAndCondition,
  }))
);

const ServicePartnerInterestForm = React.lazy(() =>
  import("./pages/ServicePartner").then((module) => ({
    default: module.ServicePartnerInterestForm,
  }))
);
const ServicePartnerPage=lazy(()=>import("./pages/landing/ServicePartner"))
const HealthSystem=lazy(()=>import("./pages/landing/HealthSystem"))
const Patients=lazy(()=>import("./pages/landing/Patients"))
const Payors=lazy(()=>import("./pages/landing/Payors"))



const PatientDetail = lazy(() => import("./pages/patient-flow/patientDetail"));

const ServicePage = lazy(() => import("./pages/ServicePage"));
const NotesList = lazy(() => import("./components/UI/Notes"));
const AddNewService = lazy(() => import("./pages/case-manger/AddNewService"));
const DoctorsList = lazy(() => import("./components/UI/Doctors"));
const Alerts = lazy(() => import("./components/UI/Alerts"));

const AddPatient = lazy(() =>
  import("./components/UI/patients/AddPatient/AddPatient")
);



const AppointmentDetail = lazy(() =>
  import("./pages/case-manger/serviceDetail")
);

function PrivateOutlet() {
  const { isAuth, token } = useSelector(({ user }) => user);
  return (isAuth && token )? <Outlet /> : <Navigate to="/" replace />;
}

toast.configure();
function App() {
 
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACE_API,
    libraries: ["places"],
  });
  const UserRoutes = () => (
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/service-partner" element={<ServicePartnerPage/>}/>
      <Route exact path="/health-system" element={<HealthSystem/>}/>
      <Route exact path="/patients" element={<Patients/>}/>
      <Route exact path="/payors" element={<Payors/>}/>
      <Route element={<PrivateOutlet />}>
        <Route exact path="/home" element={<HomePage />} />
      </Route>
      
      <Route
        exact
        path="/service-partner/interest"
        element={<ServicePartnerInterestForm />}
      />
      <Route exact path="/service" element={<ServicePage />} />
      <Route exact path="/booking" element={<BookingPage />} />
      <Route exact path="/booking-profile" element={<ProfileDetail />} />
      <Route exact path="/booking-setting" element={<Setting />} />
      <Route exact path="/booking-insurance" element={<InsuranceDetail />} />
      <Route exact path="/services/:serviceId" element={<ServiceDetail />} />
      <Route exact path="/family-member" element={<FamilyMember />} />
      <Route exact path="/family-profile" element={<FamilyInsuranceDetail />} />
      <Route exact path="/family-insurance" element={<FamilyProfileDetail />} />
      <Route exact path="/notification" element={<NotificationPage />} />
      <Route exact path="/address" element={<YourAddress />} />
      <Route exact path="/order" element={<OrderPage />} />
      <Route exact path="/wallet" element={<Wallet />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route
        exact
        path="/privacy-for-app-policy"
        element={<PrivacyPolicy headerDisable={true} />}
      />
      <Route
        exact
        path="/data-for-app-policy"
        element={<DataPolicy headerDisable={true} />}
      />
      <Route exact path="/data-policy" element={<DataPolicy />} />
      <Route exact path="/about-us2" element={<AboutUs2 />} />
      <Route exact path="/about-us" element={<AboutUs />} /> 
      <Route exact path="/job-application" element={<JobApplication />} /> 
      
      <Route
        exact
        path="/terms-and-condition"
        element={<TermsAndCondition />}
      />
      <Route exact path="/faq" element={<FaqScreen />} />
    </Routes>
  );

  const CaseMangerRoutes = () => (
    <Routes>
      <Route element={<PrivateOutlet />}>
        <Route exact path="/case-manager" element={<LoginCaseManager />} />
        <Route
          exact
          path="/case-manager/login"
          element={<LoginCaseManager />}
        />
        <Route
          exact
          path="/case-manager/bookings"
          element={<AppointmentPage />}
        />

<Route
          exact
          path="/case-manager/addsurvey"
          element={<SurveyForm />}
        />
        <Route
          exact
          path="/case-manager/survey"
          element={<Survey />}
        />
        <Route exact path="/case-manager/preauth" element={<PreAuthForm />} />
          <Route
          exact
          path="/doctor/appointments"
          element={<DoctorsAppointmentsList />}
        />
         <Route
          exact
          path="/case-manager/referral/incoming"
          element={<DoctorReferralPageIncoming />}
        />
          <Route
          exact
          path="/case-manager/referral/outgoing"
          element={<DoctorReferralPageOutgoing />}
        />
        <Route
          exact
          path="/case-manager/bookings/:id"
          element={<AppointmentDetail />}
        />
         <Route
          exact
          path="/case-manager/survey/:id"
          element={<SurveySubmissions />}
        />

        <Route
          exact
          path="/case-manager/patient/list"
          element={<PatientList />}
        />
        <Route
          exact
          path="/case-manager/patient/detail/:id"
          element={<PatientDetail />}
        />
          <Route
          exact
          path="/case-manager/patient/details/:id"
          element={<PatientDetail />}
        />
         <Route
          exact
          path="/athena/"
          element={<AthenaComponent />}
        />
        
        <Route
          exact
          path="/case-manager/patient/create"
          element={<AddPatient />}
        />

        <Route
          exact
          path="/case-manager/request-service"
          element={<AddNewService />}
        />
        <Route
          exact
          path="/case-manager/request-service-two"
          element={<RequestsPageStepTwo />}
        />
        <Route
          exact
          path="/case-manager/request-service-three"
          element={<RequestsPageStepThree />}
        />
        <Route
          exact
          path="/case-manager/request-service-four"
          element={<RequestsPageStepFour />}
        />
        <Route
          exact
          path="/case-manager/request-service-five"
          element={<RequestsPageStepFive />}
        />
        <Route
          exact
          path="/case-manager/request-service-six"
          element={<RequestsPageStepSix />}
        />

        <Route
          exact
          path="/case-manager/profile-detail"
          element={<ProfileDetailCaseManagerFeedback />}
        />
        <Route exact path="/case-manager/notification" element={<Alerts />} />

        <Route exact path="/case-manager/notes" element={<NotesList />} />
        <Route exact path="/case-manager/analytics" element={<ChartComponent />} />

        <Route
          exact
          path="/case-manager/add-patient-address/:userId"
          element={<AddPatientAddress />}
        />
        <Route
          exact
          path="/case-manager/add-patient-address"
          element={<AddPatientAddress />}
        />
        {/*  */}
        <Route
          exact
          path="/case-manager/add-patient-insurance/:userId"
          element={<AddPatientInsurance />}
        />
        <Route
          exact
          path="/case-manager/add-patient-insurance"
          element={<AddPatientInsurance />}
        />
        {/*  */}
        <Route
          exact
          path="/case-manager/add-patient-insurance/:userId"
          element={<AddPatientInsurance />}
        />
        <Route
          exact
          path="/case-manager/add-patient-insurance"
          element={<AddPatientInsurance />}
        />
        {/*  */}
        <Route
          exact
          path="/case-manager/appointment-list/:userId"
          element={<PatientAppointmentList />}
        />
        <Route
          exact
          path="/case-manager/appointment-list"
          element={<PatientAppointmentList />}
        />
        {/*  */}
        <Route exact path="/payment-success" element={<PaymentSuccess />} />
      </Route>
    </Routes>
  );

  const ProviderRoutes = () => (
    <Routes>
      <Route element={<PrivateOutlet />}>
        <Route exact path="/provider" element={<DoctorsList />} />
      </Route>
    </Routes>
  );

  const ConferenceFormRoutes = () => (
    <Routes>
      <Route >
        <Route exact path="/csconference" element={<ConferenceForm />} />

      </Route>
    </Routes>
  );

  const PublicRoutes = () => (
    <Routes>
      <Route>
      <Route exact path="/valleyoaks" element={<DemoScreenPage />} />
        <Route exact path="/release-notes" element={<ReleaseNotes />} />
        <Route exact path="/rating" element={<RatingForm />} />
        <Route exact path="/sf-12" element={<OutgoingSurveyForm />} />
      </Route>
    </Routes>
  );
  return (
    <Suspense
      fallback={
        <div
          style={{ height: "100vh" }}
          className="d-flex  w-100 justify-content-center align-items-center"
        >
          <img src="assets/images/logo-black.svg" alt="medsetgo" />
        </div>
      }
    >
      <div className="App">
        <Router>
          <ScrollToTop />

          <UserRoutes />
          <CaseMangerRoutes />
          <ProviderRoutes />
          <ConferenceFormRoutes />
          <PublicRoutes/>
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
