import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Modal, Button } from "antd";
import "react-toastify/dist/ReactToastify.css";
import { setFcmToken } from "../features/auth/authSlice";
import { useDispatch } from "react-redux";
import { RequestPermission, onMessageListener } from "../firebase";
import "./style/style.css";

const ChatPushNotification = () => {
  const dispatch = useDispatch();
  const [notification, setNotification] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(
    Notification.permission
  );

  const getFcmToken = async () => {
    const fcmToken = await RequestPermission();
    dispatch(setFcmToken(fcmToken));
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleAllowPress = () => {
    Notification.requestPermission();
    setModalVisible(false);
  };

  const showPermissionPopup = async () => {
    const fcmToken = await RequestPermission();
    dispatch(setFcmToken(fcmToken));
    setNotificationPermission(Notification.permission);
  };

  useEffect(() => {
    if (notificationPermission === "default") {
      showPermissionPopup();
    } else if (notificationPermission === "denied") {
      dispatch(setFcmToken(""));
    } else if (notificationPermission === "granted") {
      getFcmToken();
    }
  }, [notificationPermission]);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        console.log("payload", payload);
        setNotification(payload);
        let sound = new Audio();
        sound.src = "/assets/audio/notification_sound.wav";
        sound.load();
        sound.play().catch(function (error) {
          console.log(
            "Chrome cannot play sound without user interaction first"
          );
        });

        toast(<Message notification={payload?.notification} />);
      })
      .catch((err) => console.log("failed: ", err));
  }, [notification]);

  return (
    <>
      <ToastContainer />
      <CustomModal
        visible={modalVisible}
        onClose={handleCloseModal}
        onAllowPress={handleAllowPress}
      />
    </>
  );
};

const Message = ({ notification }) => {
  return (
    <>
      <div id="notificationHeader">
        {/* image is optional */}
        {notification?.image && (
          <div id="imageContainer">
            <img src={notification?.image} width={100} />
          </div>
        )}
        <span>{notification?.title}</span>
      </div>
      <div id="notificationBody">{notification?.body}</div>
    </>
  );
};

const CustomModal = ({ visible, onClose, onAllowPress }) => {
  return (
    <Modal
      title="Allow Notifications"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onAllowPress}>
          Allow
        </Button>,
      ]}
    >
      <p>Medsetgo wants you to allow notifications</p>
    </Modal>
  );
};

export default ChatPushNotification;
