import React, { useState } from "react";
import { Drawer, Row, Col } from "antd";
import AttachmentCard from "../cards/AttachmentCard";
import AttachmentPreview from "../modals/AttachmentPreview";
import { isMobile } from "react-device-detect";

const AttachmentsView = ({ data, onClose }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const openInNewTab = (url) => {
    window.open(`https://docs.google.com/gview?url=${url}&embedded=true`, '_blank');
 
  }

  const onPreview = (data) => {
    if(data?.image.includes("doc")){
         console.log("send")
         console.log(data?.image);
           openInNewTab(data?.image)
    }else{
    setPreviewOpen(true);
    setPreviewImage({
      type: data?.image.includes("pdf")
        ? "application/pdf"
        : data?.image.includes("docx")
        ? "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        : data?.image.includes("doc")
        ? "application/msword"
        : "image",
      url: data?.image,
      // title:data?.image?.substring(data?.image.lastIndexOf("/") + 1)
    });
  }
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );
  };
  const handleCancel = () => {
    setPreviewOpen(false);
    setPreviewImage(null);
  };

  return (
    <Drawer
      open
      width={isMobile ? '80%' : 575}
      placement="right"
      onClose={onClose}
      title={null}
      footer={false}
    >
      {(data ?? [])?.length > 0 ? (
        <div>
          <div className="fs-14 fw-500 lh-20 mb-1">Attachments</div>

          <Row>
            {(data ?? [])?.map((el) => (
              <Col sm={12} span={24}>
                <div className="px-2">
                  <AttachmentCard
                    hideSelection
                    url={el?.image}
                    prescriptionId={el?.prescriptionId}
                    onPreview={() => onPreview(el)}
                  />
                </div>
              </Col>
            ))}
          </Row>
        </div>
      ) : (
        <div
          style={{ height: 400 }}
          className="d-flex align-items-center justify-content-center"
        >
          <div>No Attachments Found</div>
        </div>
      )}
      {previewOpen && previewImage && (
        <AttachmentPreview data={previewImage} onClose={handleCancel} />
      )}
    </Drawer>
  );
};

export default AttachmentsView;
