import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { debounce } from "../../utils/functions";
import { CaseMangerWrapper } from "../../components/Wrapper";
import {
  getAppointments,
  updateAppointment,
} from "../../features/case-manager/caseManagerSlice";
import { Button, Input, Table, Tooltip, Dropdown, Menu } from "antd";
import "./style.css";
import { FiSearch } from "react-icons/fi";
import {
  AiFillFileUnknown,
  AiFillPlusCircle,
  AiOutlinePlusCircle,
} from "react-icons/ai";
import { NoData } from "../../components/common/Nodata/index";
import { FaFilter } from "react-icons/fa";
import FileSaver from "file-saver";
import {
  initialServiceDetailval,
  setServiceData,
  updateServiceStep,
} from "../../store/AddService/Slice";
import { serviceSteps } from "../../utils/constants";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import moment from "moment";
import PaginationUI from "../../components/common/pagination";
import { BsChatLeftTextFill, BsImages } from "react-icons/bs";

import UpdateOrder from "../../components/common/modals/UpdateOrder";
// import { RowAction } from "../../components/common/dropdowns/RowAction";
import AttachmentsView from "../../components/common/drawer/AttachmentsView";
import { setCurrentChat } from "../../store/chat/Slice";
import * as XLSX from "xlsx";
import { AiOutlinePlus } from "react-icons/ai";

import { FaGears } from "react-icons/fa6";
import { PdfViewer } from "../../components/common/Viewer";
import { BiSolidFilePdf } from "react-icons/bi";
import { APIHitCall } from "../../features/case-manager/caseManagerService";
import FormItem from "antd/es/form/FormItem";
import { makeAndsaveExcelFile } from "../../utils/functions";
import FilterOptionsDropdown from "./filter";
import { isMobile } from "react-device-detect";

export const AppointmentPage = () => {
  //console.log("gagan");
  const navigation = useNavigate();
  const dispatch = useDispatch();
  // const [currentRow, setCurrentRow] = useState(null);
  // const [showDetail, setShowDetail] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [isPdf, setPdf] = useState(null);

  const [doctorId, setdoctorId] = useState("");
  const [referralStatusId, setReferralStatusId] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [servicePartnerId, setservicePartnerId] = useState("");
  const [serviceTypeId, setserviceTypeId] = useState("");

  const [isExporting, setIsExporting] = useState(false);

  const [isUpdate, setUpdatedata] = useState(null);
  const [attachmentsData, setAttachmentsData] = useState([]);
  const [isAttchments, setIsAttachments] = useState(false);

  const [search, setSearch] = useState("");
  const { appointment, user, fcmToken } = useSelector(
    ({ caseManagerSlice, user }) => ({
      ...caseManagerSlice,
      ...user,
    })
  );

  const getListData = (FD) => {
    dispatch(getAppointments(FD));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceApicall = useCallback(debounce(getListData), []);

  const exportData = () => {
    if (!appointment || !appointment.data || appointment.data.length === 0) {
      return;
    }
    let header = [
      "OrderId",
      "Patient Name",
      "Service",
      "Schedule Date",
      "Address",
      "Phone",
      "Service Partner",
      "Payment",
      "Clinic",
      "Doctor",
    ];
    getExportData();
    async function getExportData() {
      setIsExporting(true);
      try {
        const FD = new FormData();
        FD.append("casemanager", user?.id);
        FD.append("action", "inprocessTaskcasemanager");
        FD.append("auterizetokenid", user?.id);
        FD.append("limit", 500);
        // FD.append("page_start", (page - 1) * limit);

        FD.append("doctorId", doctorId);
        FD.append("start_date", startdate);
        FD.append("end_date", enddate);
        FD.append("partnerid", servicePartnerId);
        FD.append("type", serviceTypeId);
        FD.append("jobstatus", referralStatusId);

        if (search) {
          FD.append("name", search);
        }
        const res = await APIHitCall(FD);
        setIsExporting(false);
        if (res?.data?.status === 200) {
          const data = res?.data?.result?.map((item) => ({
            OrderId: item.order_id,
            "Patient Name": item.first_name + " " + item.last_name,
            Service: item.services,
            "Schedule Date": item.schedule_datetime,
            Address: item.address,
            Phone: item.mobile,
            "Service Partner": item.parentpartnername,
            Payment: item.payment_status,
            Clinic: item.clinicname,
            Doctor: item.docname + item.doclname,
          }));
          makeAndsaveExcelFile(header, data);
        }
      } catch (error) {
        console.error(error);
        setIsExporting(false);
      } finally {
        setIsExporting(false);
      }
    }
  };
   
  const fetchData = () => {
    const FD = new FormData();
    FD.append("casemanager", user?.id);
    FD.append("action", "inprocessTaskcasemanager");
    FD.append("auterizetokenid", user?.id);
    FD.append("limit", limit);
    FD.append("page_start", (page - 1) * limit);
     
    FD.append("doctorId", doctorId);
    FD.append("start_date", startdate);
    FD.append("end_date", enddate);
    FD.append("partnerid", servicePartnerId);
    FD.append("type", serviceTypeId);
    FD.append("jobstatus", referralStatusId);

    if (search) {
      FD.append("name", search);
    }
    debounceApicall(FD);
  };

  const updateFcmToken = async () => {
    try {
      const FD = new FormData();

      FD.append("action", "updatedFCM");
      FD.append("webfcm", fcmToken);
      FD.append("auterizetokenid", user?.id);

      const res = await APIHitCall(FD);
      if (res?.data?.status === 200) {
        console.log('api message', res?.data?.msg);
      } else {
        console.log('something went wrong');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    dispatch(updateAppointment([]));
  }, []);

  useEffect(() => {
    updateFcmToken();
  }, [fcmToken]);

  useMemo(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    limit,
    page,
    // search,
    // doctorId,
    // startdate,
    // enddate,
    // servicePartnerId,
    // serviceTypeId,
    // referralStatusId,
  ]);
  
  useMemo(() => {
    setLimit(10);
    setPage(1);
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
   
    search,
    doctorId,
    startdate,
    enddate,
    servicePartnerId,
    serviceTypeId,
    referralStatusId,
  ]);

  const onUpdate = (data) => {
    setUpdatedata(data);
  };

  const onClickAddOrder = (d) => {
    const p = {
      appointmentid: "",
      user_id: d?.user_id,
      phone: d?.mobile,
      email: d?.patientemail,
      dob: d?.patientdob,
      gender: d?.gender,
      insuranceId: d?.insuranceId || "",
      orderId: d?.order_id,
    };

    dispatch(setServiceData({ ...initialServiceDetailval, ...p }));
    dispatch(updateServiceStep(serviceSteps.SELECT_ADDRESS));
    navigation("/case-manager/request-service");
  };
  const onCloseUpateOrder = () => {
    setUpdatedata(null);
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(
      `https://docs.google.com/gview?url=${url}&embedded=true`,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  const onAttachmentsView = (data) => {
    setAttachmentsData(data?.pharmacyinfo?.prescription_image ?? []);
    setIsAttachments(true);
  };
  const onCloseAttachments = () => {
    setIsAttachments(false);
    setAttachmentsData([]);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "order_id",
      render: (item) => item,
    },
    {
      title: "Name",
      dataIndex: "first_name",
      render: (item, row) =>
        `${row?.first_name ?? ""} ${row?.last_name ?? ""}` ?? row?.username,
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      render: (item) => item,
    },
    {
      title: "Type",
      dataIndex: "services",
      width: 200,
      render: (item) => item ?? "-",
    },
    {
      title: "Partner",
      dataIndex: "parentpartnername",
      render: (item) => item ?? "-",
    },
    {
      title: "Date",
      width: "12%",
      dataIndex: "schedule_datetime",
      render: (item) => (item ? moment(item).format("MM-DD-YYYY") : "N/A"),
    },
    {
      title: "Status",
      dataIndex: "job_status_val",
      render: (item) => (
        <span className={`data-${item?.toLowerCase()}`}>{item}</span>
      ),
    },
    {
      title: "Chat",
      dataIndex: "user_id",
      render: (item, row) => (
        <div
          className="p-3 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            dispatch(
              setCurrentChat({
                vendorid: row?.parentpartnerid,
                vendorname: row?.parentpartnername,
              })
            );
          }}
        >
          <BsChatLeftTextFill className="text-primary" size={20} />
        </div>
      ),
    },
    {
      title: "action",
      dataIndex: "user_id",
      align: "right",
      width: 50,
      render: (item, row) => (
        <div
          style={{ height: 40 }}
          className="d-flex text-right justify-content-end align-items-center cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Tooltip title="View Attachments">
            <BsImages
              className="hover-text-primary cursor-pointer"
              size={22}
              onClick={() => onAttachmentsView(row)}
            />
          </Tooltip>
          <Tooltip title="Update Order">
            <FaGears
              className="hover-text-primary cursor-pointer ml-1"
              size={24}
              onClick={() => onUpdate(row)}
            />
          </Tooltip>
          {row?.careplansignlink ? (
            <Tooltip title="View Pdf">
              <BiSolidFilePdf
                className="hover-text-primary cursor-pointer ml-1"
                size={22}
                // onClick={() => window.open(row?.careplansignlink, "_blank")}
                onClick={() => setPdf(row)}
              />
            </Tooltip>
          ) : (
            <AiFillFileUnknown className=" cursor-not-allowed ml-1" size={22} />
          )}

          <Tooltip title="Add Order">
            <AiFillPlusCircle
              className="hover-text-primary cursor-pointer ml-1"
              size={24}
              onClick={() => onClickAddOrder(row)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const openAppointmentDetail = (row) => {
    navigation(`/case-manager/bookings/${row?.order_id}`);
  };

  return (
    <div style={styles.main}>
      <CaseMangerWrapper>
        <section className="booking-process">
          <div className="container">
            <div className="data-table-main-box">
              {isMobile ? (
                <div>
                  <div className="datatable-list-head-btn">
                    <Input
                      value={search}
                      allowClear
                      onChange={(e) => setSearch(e.target.value)}
                      size="small"
                      prefix={<FiSearch size={14} />}
                      placeholder="search"
                      style={{ borderRadius: "6px" }}
                    />
                  </div>

                  <div className="d-flex flex-row align-items-center justify-content-between mt-3">
                    <FilterOptionsDropdown
                      onExport={() => {
                        exportData();
                      }}
                      onApply={(
                        doctorid,
                        referralStatusId,
                        startdate,
                        enddate,
                        partnerId,
                        typeId
                      ) => {
                        setdoctorId(doctorid);
                        setReferralStatusId(referralStatusId);
                        setstartdate(startdate);
                        setenddate(enddate);
                        setservicePartnerId(partnerId);
                        setserviceTypeId(typeId);
                      }}
                      onReset={() => {
                        setdoctorId("");
                        setReferralStatusId("");
                        setstartdate("");
                        setenddate("");
                        setservicePartnerId("");
                        setserviceTypeId("");
                      }}
                      isFromIncomingOutgoing={false}
                      style={{
                        width: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    />

                    <Button
                      onClick={() => {
                        if (!isExporting) {
                          setIsExporting(true);
                          exportData();
                        }
                      }}
                      type="primary"
                      style={{ width: "40%" }}
                    >
                      {isExporting ? "Exporting..." : "Export"}
                    </Button>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      className="datatable-list-head-title"
                      style={{ fontSize: "15px" }}
                    >
                      Referral List
                    </div>
                   
                    <Button
                      icon={<AiOutlinePlusCircle size={18} className="mr-1" />}
                      onClick={() => {
                        dispatch(
                          setServiceData({ ...initialServiceDetailval })
                        );
                        navigation("/case-manager/request-service");
                        dispatch(updateServiceStep(serviceSteps.SELECT_USER));
                      }}
                      type="primary"
                      style={{ padding: "10px" }}
                    >
                      Add New Referral
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="datatable-list-head appointments-list">
                  <div className="datatable-list-head-title">
                    Referral List
                  </div>
                  <div
                    className="d-flex flex-align-items-center"
                    style={{ gap: "10px" }}
                  >
                    <Input
                      value={search}
                      allowClear
                      onChange={(e) => setSearch(e.target.value)}
                      size="small"
                      prefix={<FiSearch size={14} />}
                      placeholder="search"
                    />
                    <Button
                      icon={<AiOutlinePlusCircle size={18} className="mr-1" />}
                      onClick={() => {
                        dispatch(
                          setServiceData({ ...initialServiceDetailval })
                        );
                        navigation("/case-manager/request-service");
                        dispatch(updateServiceStep(serviceSteps.SELECT_USER));
                       }}
                      type="primary"
                    >
                      Add New Referral
                    </Button>

                    <FilterOptionsDropdown
                      onExport={() => {
                        exportData();
                      }}
                      onApply={(
                        doctorid,
                        referralStatusId,
                        startdate,
                        enddate,
                        partnerId,
                        typeId
                      ) => {
                        setdoctorId(doctorid);
                        setReferralStatusId(referralStatusId);
                        setstartdate(startdate);
                        setenddate(enddate);
                        setservicePartnerId(partnerId);
                        setserviceTypeId(typeId);
                      }}
                      onReset={() => {
                        setdoctorId("");
                        setReferralStatusId("");
                        setstartdate("");
                        setenddate("");
                        setservicePartnerId("");
                        setserviceTypeId("");
                      }}
                      isFromIncomingOutgoing={false}
                    />

                    <Button
                      onClick={() => {
                        if (!isExporting) {
                          setIsExporting(true);
                          exportData();
                        }
                      }}
                      type="primary"
                    >
                      {isExporting ? "Exporting..." : "Export"}
                    </Button>
                  </div>
                </div>
              )}

              {isMobile ? (
                <>
                  {(appointment?.data ?? []).map((row) => {
                    let serviceDate = moment(row.schedule_datetime).format(
                      "MM-DD-YYYY"
                    );

                    const styles = {
                      fontSize: "12px",
                      fontWeight: "400",
                    };

                    let orderStatus = "";
                    let textColor = "#55B670";

                    if (row.job_status == "0") {
                      orderStatus = "Pending";
                      textColor = "#EC7F78";
                    } else if (row.job_status == "1") {
                      orderStatus = "Assigned";
                      textColor = "#00BEDA";
                    } else if (row.job_status == "2") {
                      orderStatus = "Confirmed";
                      textColor = "#00BEDA";
                    } else if (row.job_status == "4") {
                      orderStatus = "In Progress";
                      textColor = "#00BEDA";
                    } else if (row.job_status == "6") {
                      orderStatus = "Completed";
                      textColor = "#55B670";
                    } else if (row.job_status == "3") {
                      orderStatus = "Cancelled";
                      textColor = "#EC7F78";
                    }

                    return (
                      <div
                        className="card d-flex flex-row align-items-start py-3 px-2 mt-4"
                        onClick={() => openAppointmentDetail(row)}
                      >
                        <div
                          style={{ marginLeft: "5px" }}
                          className="container"
                        >
                          <h6 style={{ marginTop: "12px" }}>
                            {`${row.first_name}  ${row.last_name}`}
                          </h6>
                          <span
                            style={styles}
                          >{`Service: ${row.services}`}</span>
                          <br />
                          <span
                            style={styles}
                          >{`Service Partner: ${row.parentpartnername}`}</span>
                          <br />
                          <span
                            style={styles}
                          >{`Service Date: ${serviceDate}`}</span>
                          <br />
                          <span style={styles}>{`Phone: ${row.mobile}`}</span>
                          <br />
                          <span style={styles}>
                            {`Status: `}
                            <span style={{ color: textColor }}>
                              {orderStatus}
                            </span>
                          </span>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: "3px",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="p-1 cursor-pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              dispatch(
                                setCurrentChat({
                                  vendorid: row?.parentpartnerid,
                                  vendorname: row?.parentpartnername,
                                })
                              );
                            }}
                          >
                            <BsChatLeftTextFill
                              className="text-primary"
                              size={20}
                              style={{ marginTop: "6px" }}
                            />
                          </div>

                          <>
                            <BsImages
                              className="hover-text-primary cursor-pointer"
                              size={22}
                              style={{ marginTop: "8px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onAttachmentsView(row);
                              }}
                            />
                          </>

                          <>
                            <FaGears
                              className="hover-text-primary cursor-pointer"
                              size={24}
                              style={{ marginTop: "8px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onUpdate(row);
                              }}
                            />
                          </>

                          {row?.careplansignlink ? (
                            <>
                              <BiSolidFilePdf
                                className="hover-text-primary cursor-pointer"
                                size={22}
                                style={{ marginTop: "8px" }}
                                // onClick={() => window.open(row?.careplansignlink, "_blank")}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setPdf(row);
                                }}
                              />
                            </>
                          ) : (
                            <AiFillFileUnknown
                              className=" cursor-not-allowed"
                              size={22}
                              style={{ marginTop: "8px" }}
                            />
                          )}

                          <>
                            <AiFillPlusCircle
                              className="hover-text-primary cursor-pointer"
                              size={24}
                              style={{ marginTop: "8px" }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onClickAddOrder(row);
                              }}
                            />
                          </>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="data-table-contain">
                  <Table
                    loading={appointment?.loading || false}
                    locale={{ emptyText: <NoData /> }}
                    pagination={{
                      responsive: true,
                      hideOnSinglePage: false,
                      onChange: setPage,
                      pageSize: limit ?? 20,
                      total: appointment?.count || 0,
                      current: page ?? 1,

                      showSizeChanger: false,
                      showTotal: (total, range) => (
                        <PaginationUI
                          total={total}
                          range={range}
                          limit={limit ?? 20}
                          onPageChange={setPage}
                          page={page ?? 1}
                          onLimitChange={setLimit}
                        />
                      ),
                    }}
                    columns={columns}
                    dataSource={appointment?.data ?? []}
                    onRow={(row) => ({
                      onClick: () => openAppointmentDetail(row),
                    })}
                    selectableRowsVisibleOnly
                  />
                </div>
              )}
            </div>
          </div>
        </section>

        {isUpdate && (
          <UpdateOrder
            data={isUpdate}
            reloadData={fetchData}
            onClose={onCloseUpateOrder}
          />
        )}
        {isAttchments && (
          <AttachmentsView
            data={attachmentsData}
            onClose={onCloseAttachments}
          />
        )}

        {isPdf?.careplanlink && (
          <PdfViewer
            onClose={() => setPdf(null)}
            url={isPdf?.careplanlink}
            esignLink={isPdf?.careplansignlink}
          />
        )}
      </CaseMangerWrapper>
    </div>
  );
};
