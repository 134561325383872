import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const isFCMSupported = () => {
  return "serviceWorker" in navigator && "PushManager" in window;
};

const firebaseConfig = {
  apiKey: "AIzaSyBmh_ksllvQzhwU9PWh3ksm_npFsUy-ZLc",
  authDomain: "medsetgo-89cc0.firebaseapp.com",
  databaseURL: "https://medsetgo-89cc0-default-rtdb.firebaseio.com",
  projectId: "medsetgo-89cc0",
  storageBucket: "medsetgo-89cc0.appspot.com",
  messagingSenderId: "717667186048",
  appId: "1:717667186048:web:23eb584ea21d5e09937d65",
  measurementId: "G-8WZLLJD4BV",
};

let app = null;
let messaging = null;
if (isFCMSupported()) {
  app = initializeApp(firebaseConfig);
  // Messaging service
  messaging = getMessaging(app);
}

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
// Messaging service
//const messaging = getMessaging(app);

export const RequestPermission = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BG9GtybOtaeCBCKfd9rHKxtuIxopPJhWU7km0SlskGEPu7pl8GAy236R4WlXS2clsQrSWSpBNbb3Yns9_c0kTb4",
        });
        console.log("Firebase Token:", token);
        resolve(token);
      } else if (permission === "denied") {
        console.log("Notification permission are denied.");
        resolve("");
      } else {
        console.log("Notification permission are default.");
        resolve("");
      }
    } catch (error) {
      console.error(
        "An error occurred while requesting notification permission or getting the token.",
        error
      );
      resolve("");
    }
  });
};

//RequestPermission();

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
