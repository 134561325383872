import React, { useEffect, useState } from "react";
import Wrapper from "../components/Wrapper";
import { useDispatch, useSelector } from "react-redux";
import { getOrders,  } from "../features/auth/authSlice";
import { Spin } from "antd";

const OrderPage = () => {
  const dispatch = useDispatch();
  const { token } = useSelector(({ user }) => user);
  const [ results, setResult ] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (!token) {
        // await dispatch(getTokenAsync());
      }
      return dispatch(getOrders("payload"));
    }
    fetchData()
        .then((response)=>{
          setResult(response.payload.result);
        })
        .catch((error)=>{
          console.debug(error);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return (
    <Wrapper>
      <section className="booking-process">
        <div className="container">
          <div className="profile-main-box">
            <div className="profile-tabs">
              <ul className="flex align-items-center">
                <li>
                  <a href="#" className="active">
                    Orders
                  </a>
                </li>
              </ul>
            </div>

            <div className="tabs-info-con order-tab">
              {results?.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "10%",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <ul>
                  {results?.map((data, key) => (
                    <li key={key}>
                      <div className="ordr-info flex">
                        <div className="order-date">
                          {/*<p>21</p>*/}
                          <span>{data.execution_date}</span>
                        </div>

                        <div className="order-info-data">
                          <p>
                            {data.sub_category[0] !== undefined
                              ? data.sub_category[0].sub_category_name
                              : "  "}{" "}
                          </p>
                          <span>
                            PRA-{data.order_id}
                            <br />{" "}
                            {data.sub_category[0] !== undefined
                              ? data.sub_category[0].category_name
                              : "  "}
                            {data.sub_category[0] !== undefined ? <br /> : ""}
                            {data.order_created_at}
                            {data.order_created_at !== "" ? <br /> : ""}
                            Booked for: {data.name} {data.last_name}
                          </span>
                        </div>

                        <div className="cancelled-btn desktop-btn">
                          <button className="btn btn-primary">
                            {data.job_status_val}
                          </button>
                        </div>
                      </div>
                      <div class="order-action">
                        <button class="btn btn-outline">Book again</button>
                        <button class="btn btn-outline">View detail</button>
                        <button class="btn mobile-btn">Cancelled</button>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};
export default OrderPage;