import axios from "./../../config/api-client";
const PATH = {
  main: "main.php",
}
 
export const getPatientListService = (payload,) =>
axios.post(PATH.main, payload);

export const APIHitCall = (payload, ) =>
  axios.post(PATH.main, payload);

export const createPatientService = (payload,) =>
axios.post(PATH.main, payload);
